<template>
  <div class="cajaSuperior">
    <b-row>
      <b-col cols="12" md="4">
        <div class="menu-lateral-personas">
          <b-list-group>
            <b-list-group-item
              class="d-flex flex-wrap justify-content-center align-items-center"
            >
              <b-avatar
                variant="secondary"
                text="BV"
                class="mr-3"
                size="6em"
              ></b-avatar>
              <h5 class="text-center mt-2">Persona nombre</h5>
            </b-list-group-item>

            <b-list-group-item
              :to="{ name: 'misDatos' }"
              v-bind:class="{ activo: $route.name == 'misDatos' }"
            >
              <div class="botones-submenu">Mis datos personales</div>
            </b-list-group-item>

            <b-list-group-item
              :to="{ name: 'misMascotas' }"
              v-bind:class="{ activo: $route.name == 'misMascotas' }"
            >
              <div class="botones-submenu">Mis mascotas</div>
            </b-list-group-item>

            <b-list-group-item 
              :to="{ name: 'misSolicitudes' }"
              v-bind:class="{ activo: $route.name == 'misSolicitudes' }"
            >
              <div class="botones-submenu">Mis solicitudes adopción</div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>

      <b-col cols="12" md="8">
        <router-view />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "VistaPersonas",
  data() {
    return {
      persona: {},
    };
  },
  mounted() {
    if (localStorage.getItem("persona")) {
      try {
        this.persona = JSON.parse(localStorage.getItem("persona"));
        console.log('esto es la persona', this.persona)
      } catch (e) {
        localStorage.removeItem("persona");
      }
    }
      
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

@media (max-width: 768px){
  .menu-lateral-personas{
    margin-bottom: 10px
  }
}

.menu-lateral-personas {
  background-color: #00763f;
  border-radius: 15px;
  color: #fff;
}

.menu-lateral-personas .list-group-item {
  background: none;
  border: none;
}

.botones-submenu {
  margin: 0px;
  padding: 10px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
}

.activo .botones-submenu {
  color: #fee915;
  border-color: #fee915;
}
</style>